<template>
  <div class="container">

    <table-page dataName="activities"
                ref="table"
                :search="search"
                :searchFun="searchFun"
                :params="{type:'REDUCE'}"
                :tabList="tabList"
                :request="fetchActivies">
      <template slot="button">
        <el-button type="primary"
                   @click="$router.push('setPayment')">添加活动</el-button>
      </template>

      <el-table-column prop="title"
                       show-overflow-tooltip
                       label="活动名称">
      </el-table-column>
      <el-table-column prop="wx_stock_id"
                       show-overflow-tooltip
                       label="微信批次号">
      </el-table-column>
      <el-table-column label="活动区域"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{`${scope.row.list_province || ''}${scope.row.list_city || ''}${scope.row.list_district || ''}${scope.row.list_net || ''}`}}</span>
        </template>

      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="指定邮储卡">
        <template slot-scope="scope">
          {{scope.row.is_postal == 1?'是':'否'}}
        </template>
      </el-table-column>
      <el-table-column label="活动状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 'NOW_PROCEED'">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>进行中
          </span>
          <span v-else-if="scope.row.status === 'NOT_PROCEED'">
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>未开始
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>已结束
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="user_total_receive"
                       show-overflow-tooltip
                       label="起止时间">
        <template slot-scope="scope">
          {{scope.row.started_at}}-{{scope.row.ended_at}}}
        </template>
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="操作"
                       width="250px"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button type="text"
                     @click="$refs.relevance.showModel(scope.row.id)">关联商户</el-button>
          <el-button type="text"
                     @click="$router.push({name:'SetPayment',query:{id:scope.row.id}})">编辑</el-button>
          <el-button type="text"
                     v-permission="['ADMIN']"
                     @click="delActivity(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </table-page>
    <Relevance ref="relevance"
               @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import Relevance from './components/Relevance'
import { fetchActivies, delActivity } from '@/api/market'
export default {
  name: 'Payment',
  data () {
    return {
      fetchActivies,
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'NOT_PROCEED',
        label: '未开始'
      }, {
        value: 'NOW_PROCEED',
        label: '进行中'
      }, {
        value: 'END_PROCEED',
        label: '已结束'
      }],

      search: [{ type: 'area' }, {
        type: 'input',
        name: '微信批次号',
        placeholder: '请输入微信批次号',
        key: 'wx_stock_id',
        value: ''
      }]

    }
  },
  components: {
    TablePage,
    Relevance
  },
  methods: {
    searchFun (res) {
      const param = Object.assign({}, res)
      param.list_province = res.province
      param.list_city = res.city
      param.list_district = res.district
      param.list_net = res.net
      delete param.province
      delete param.city
      delete param.district
      delete param.net
      return param
    },
    delActivity (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delActivity({
          activity_ids: [id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
